import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import React, { useState, useEffect, useRef } from 'react';
import Footer from './components/Footer';
import MobileHeader from './components/MobileHeader';
function About() {
  
  return (
   <div className='main-holder'>
    <MobileHeader />
    <div className="main1">
    <Header />
    <div className='main-side'>
     <div className='main-text-center1'>
       <p className='main-text-header'>About Me</p>
       <p className='main-text-additional-details1'>I am a sophomore at Tufts University double majoring in Computer Engineering and Applied Physics. I've been developing software for 6 years and throughout this period I have had professional experience, freelance work, and individual projects contributing to my proficiency in web, app, and AI technologies. My most recent professional experience was at Modo Labs, where I interned as an app developer. At this internship, I utilized the AWS environment to improve university applications. I am a passionate engineer and love to learn

       </p>
       </div>
    </div>
    <div className='main-side'>
    
 <img src={require('./suitnobg.png')} className='profilephotosuit'/>
    </div>
   
    
   
   </div>
   <div className="main2">

    <div className='main2-side'>
     <div className='main-text-center2'>
       <p className='main-text-header'>About Me</p>
       <p className='main-text-additional-details1'>I am a sophomore at Tufts University double majoring in Computer Engineering and Applied Physics. I've been developing software for 6 years and throughout this period I have had professional experience, freelance work, and individual projects contributing to my proficiency in web, app, and AI technologies. My most recent professional experience was at Modo Labs, where I interned as an app developer. At this internship, I utilized the AWS environment to improve university applications. I am a passionate engineer and love to learn.

       </p>
       </div>
    </div>
    <div className='main2-side'>
    
 <img src={require('./suitnobg.png')} className='profilephotosuit'/>
    </div>
   
    
   
   </div>
   <Footer />
   </div>
  );
}

export default About;
