import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import React, { useState, useEffect, useRef } from 'react';
import Footer from './components/Footer';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import MobileHeader from './components/MobileHeader';
function Portfolio() {
  const navigate = useNavigate();
  const navProject = (i) => {
    navigate("/Project", {state:{i:i}})
  }
  const cards = [
    { id: 3, title: 'Health AI', image: './projects/health/main.png' },
    { id: 5, title: 'Python Class Final Project AI', image: './projects/es2/main.png' },
    { id: 4, title: 'Internship Project', image: './projects/internship/main.png' },
    { id: 0, title: 'EduGold', image: './projects/edugold/main.png' },
    { id: 2, title: 'Syringe Driver', image: './projects/syringe_driver/main.png' },
    { id: 1, title: 'Graphing Animation Software', image: './projects/mathimate/main.png' },
    { id: 6, title: 'This Portfolio Website!', image: './projects/this/main.png' },
  ];
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };
  
  const cardVariants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
  };
  return (
    <div className='portfolio-main'>
       <MobileHeader />
     <Header />
     <div className='portfolio-header'>
     <p className='portfolio-header-text'>
     My Portfolio
     </p>
     </div>
     
     <motion.div
      className="grid"
      variants={containerVariants}
      initial="hidden"
      animate="show"
    >
      {cards.map((card, index) => (
        <motion.div
          className="project-card"
          key={index}
          onClick={() => navProject(card.id)}
          style={{ backgroundImage: `url(${require(`${card.image}`)})` }}
          variants={cardVariants}
          whileHover={{ scale: 1.05, boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)" }}
        >
          <p className="project-card-title">{card.title}</p>
        </motion.div>
      ))}
    </motion.div>
    
     

     <Footer />
    </div>
  );
}

export default Portfolio;
